import React from 'react';

import Layout from '../components/Layout';
import { injectIntl } from 'gatsby-plugin-react-intl';

const NotFoundPage = ({ intl }) => (
    <Layout>
        <div
            style={{
                padding: 120,
                flex: 1,
                textAlign: 'center',
                height: '100%',
            }}
        >
            <h1>{intl.formatMessage({ id: '404-title' })}</h1>
            <p>{intl.formatMessage({ id: '404-subtitle' })}</p>
            <p>
                {intl.formatMessage({ id: 'kenra-redirect-body-1' })}{' '}
                <a href="\" style={{ textDecoration: 'underline' }}>
                    {intl.formatMessage({ id: 'kenra-redirect-body-2' })}
                </a>
            </p>
            <img src="/images/notfound.png" alt="" style={{ maxHeight: 450 }} />
        </div>
    </Layout>
);

export default injectIntl(NotFoundPage);
